<template>
  <v-container>
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("use_overtime.title") }}</h1>
      </div>
    </div>
    <hr />
    <br />
    <v-row>
      <v-col cols="12" md="4" lg="4" sm="12" class="mb-4">
        <v-card max-width="400" class="mx-auto overflow-hidden">
          <div class="bg-head">
            <div class="pa-4 ml-6">
              <v-icon large color="white darken-2">
                mdi-alarm
              </v-icon>

              <span class="text ml-4 lfont">OT ທັງໝົດ</span>
            </div>
          </div>

          <v-card-text>
            <div class="text text--primary lfont">ຈຳນວນ OT ທັງໝົດ</div>
            <p class="text-h4 text--primary">
              <span class="lfont" v-if="qty_remaining.days > 0">
                {{ qty_remaining.days }}
                {{ $t("ot_report.day") }}
              </span>
              <span class="lfont" v-if="qty_remaining.hours > 0">
                {{ qty_remaining.hours }}
                {{ $t("ot_report.hour") }}
              </span>
              <span class="lfont" v-if="qty_remaining.minutes > 0">
                {{ qty_remaining.minutes }}
                {{ $t("ot_report.minute") }}
              </span>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col cols="3" md="1" sm="1" lg="1">
        <div class="btn-new-create-action mt-2">
          <v-btn
            class="mt-5 btn-create lfont btn"
            color="info"
            @click="onCreate"
          >
            {{ $t("employee_ot.create") }}</v-btn
          >
        </div>
      </v-col>
      <v-col cols="9" sm="6" md="6" lg="6">
        <label class="label-input">{{ $t("employee_ot.date") }}</label>
        <DatePicker
          class="date-time"
          style="width: 100%"
          type="date"
          v-model="date"
          valueType="format"
          slot="activator"
          :append-to-body="true"
          name="founding_date"
          @change="searchFilterItem()"
        ></DatePicker>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        sm="12"
        lg="3"
        class="mb-4"
        v-for="(item, idx) in listEmployeeUse"
        :key="idx"
      >
        <v-card class="mx-auto">
          <v-card-text>
            <v-row class="lfont">
              <v-col cols="12" sm="12" md="12" class="mb-3 pt-3">
                <div class="d-flex justify-space-between">
                  <span>{{ $t("employee_ot.table.date") }}</span>
                  <span>{{ item.date }}</span>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mb-3">
                <div class="d-flex justify-space-between">
                  <span>{{ $t("use_overtime.emp_leave_time") }}</span>
                  <span>{{ item.start }} - {{ item.end }}</span>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mb-3">
                <div class="d-flex justify-space-between">
                  <span>{{ $t("employee_ot.table.qty_hour") }}</span>
                  <span>
                    <span v-if="item.qty.hour > 0">
                      {{ item.qty.hour }} {{ $t("employee_ot.table.hour") }}
                    </span>

                    <span v-if="item.qty.minute > 0">
                      {{ item.qty.minute }}
                      {{ $t("employee_ot.table.minute") }}
                    </span>
                  </span>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mb-3">
                <div class="d-flex justify-space-between">
                  <span>{{ $t("employee_ot.table.status") }}</span>
                  <span>
                    <span
                      v-if="item.using_status === 'pending'"
                      style="color: orange;"
                      >{{ item.using_status }}</span
                    >
                    <span
                      v-else-if="item.using_status === 'approve'"
                      style="color: green;"
                      >{{ item.using_status }}</span
                    >
                    <span v-else style="color: red;">{{
                      item.using_status
                    }}</span>
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-actions>
            <span v-if="item.using_status == 'pending'">
              <v-btn text color="success accent-4" @click="onEdit(item.id)">
                Edit
              </v-btn>
              <v-btn text color="error accent-4" @click="onDeleted(item.id)">
                Delete
              </v-btn>
            </span>
            <span v-else>
              <v-btn
                disabled
                text
                color="success accent-4"
                @click="onEdit(item.id)"
              >
                Edit
              </v-btn>
              <v-btn
                text
                disabled
                color="error accent-4"
                @click="onDeleted(item.id)"
              >
                Delete
              </v-btn>
            </span>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- <defaultTableNoResult v-else /> -->
    <ModalDelete fullscreen title="Edit Employee Leave">
      <template v-slot="{ close }">
        <Delete
          :employee_ot_use_id="employee_ot_use_id"
          @close="close"
          @success="fetchEmployeeUse()"
        />
      </template>
    </ModalDelete>
    <Pagination
      v-if="pagination.total_pages > 1"
      :pagination="pagination"
      :offset="offset"
      @paginate="fetchEmployeeUse"
    >
    </Pagination>
    <Loading v-if="isLoading" />
  </v-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import Loading from "@/components/Loading";
import Delete from "./Crud/Delete";

export default {
  components: {
    DatePicker,
    Pagination,
    defaultTableNoResult,
    Loading,
    Delete,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      month: "",
      months: [],
      offset: 10,
      pagination: {},
      employeeOt: {},
      qty_remaining: {},
      per_page: 10,
      isLoading: true,
      listEmployeeUse: [],
      employee_ot_use_id: "",
      isLoading: true,
      date: null,
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchEmployeeUse();
      this.pagination.current_page = 1;
    },
    onCreate() {
      this.$router
        .push({
          name: "employee_use_ot.create",
        })
        .catch(() => {});
    },
    onEdit(item) {
      this.$router.push({
        name: "employee_use_ot.update",
        params: {
          employee_ot_use_id: item,
        },
      });
    },
    onDeleted(employee_ot_use_id) {
      this.employee_ot_use_id = employee_ot_use_id;
      this.$store.commit("modalDelete_State", true);
    },

    fetchEmployeeHourTotal() {
      this.isLoading = true;
      this.$axios
        .get(`employee/conclude/ot`, {
          params: { month: this.month, year: this.year },
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.isLoading = false;
            // this.qty_receive = res.data.data.qty_receive;
            // this.qty_working = res.data.data.qty_working;
            // this.qty_using = res.data.data.qty_using;
            // this.qty_request = res.data.data.qty_request;
            this.qty_remaining = res.data.data.qty_remaining;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    fetchEmployeeUse() {
      this.isLoading = true;
      this.$axios
        .get(`employee/using/ot`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            date: this.date,
          },
        })
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            this.isLoading = false;
            this.listEmployeeUse = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listEmployeeUse.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchEmployeeUse();
    this.fetchEmployeeHourTotal();
  },
};
</script>

<style lang="scss" scoped>
.bg-head {
  background-color: #03a9f4 !important;
}
.bg-head1 {
  background-color: #00c853 !important;
}
.bg-head2 {
  background-color: #fb8c00 !important;
}
.text {
  color: white !important;
}
</style>
